import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
//import ExampleCarouselImage from 'components/ExampleCarouselImage';

import S1 from '../../images/407713520.png';
import S2 from '../../images/137909632.png';
import S3 from '../../images/571626413.png';
import S4 from '../../images/471311300.png';
import S5 from '../../images/715263221.png';
import S6 from '../../images/330513305.png';
import S7 from '../../images/397980226.png';
import S8 from '../../images/380907196.png';

function Slider() {
    return (
      <Carousel>



    <Carousel.Item>
      <img src={S1} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Modern business transformation</h3>
          <p className='slider-p-white'>Adapt and optimize your business to meet evolving technology needs and unlocking new growth opportunites.</p>
        </Carousel.Caption>
      </Carousel.Item>

    <Carousel.Item>
      <img src={S2} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Digital transformation</h3>
          <p className='slider-p-white'>Lead your business through a successful digital transformation with strategy.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S3} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Artificial Intelligence (AI)</h3>
          <p className='slider-p-white'>Build bespoke data science, machine learning and artificial intelligence solutions.</p>
        </Carousel.Caption>
      </Carousel.Item>
      
      <Carousel.Item>
      <img src={S4} className="d-block w-100" alt="Second slide"/>
        <Carousel.Caption>
          <h3>Business operations & support</h3>
          <p className='slider-p-white'>Offer end-to-end operational support, including software optimization, maintenance, and technical assistance, ensuring your business runs smoothly while you focus on growth.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={S5} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Cloud Security & compliance</h3>
          <p className='slider-p-white'>Ensure robust security and compliance, responsible AI & safeguarding your data while meeting industry standards and regulatory requirements.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S6} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Data strategy</h3>
          <p className='slider-p-white'>Data strategic insights, and engineering solutions to drive informed decision-making, optimize processes, and fuel business growth.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S7} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption>
          <h3>Data analysis & visualization</h3>
          <p className='slider-p-white'>Data analysis, visualization, and dashboards to effectively track and measure business progress.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img src={S8} className="d-block w-100" alt="Third slide"/>
        <Carousel.Caption>
          <h3>Business possiblities</h3>
          <p className='slider-p-white'>Unlocking new growth opportunites - driving success through innovation and collaboration.</p>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
    );

}

export default Slider;