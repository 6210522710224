import React from "react";
import GoogleMap from "../inc/GoogleMap";

function ContactUs() {
    return (
        <section className="section">
            <div className="container">
                <div className="row">
                    <div className='col-md-12 mb-5 text-center'>
                        <h3 className='main-heading'>Support</h3>
                        <div className='underline mx-auto'></div>
                    </div>
                </div>
                <div className="card shadow">

                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <h4>Contact Form</h4>
                                <hr />
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">First Name</label>
                                    <input type="text" className="form-control" placeholder="Enter First name" />
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Enter Last name" />
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Email</label>
                                    <input type="text" className="form-control" placeholder="Enter Email" />
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Phone</label>
                                    <input type="text" className="form-control" placeholder="Enter Phone" />
                                </div>
                                <div className="form-group">
                                    <label for="" name="" className="mb-1">Message</label>
                                    <textarea rows="3" className="form-control" placeholder="Type your message" />
                                </div>
                                <div className="form-group py-3">
                                    <button type="button" className="btn btn-primary shadow w-100">Send Message</button>
                                </div>
                            </div>
                            <div className="col-md-6 border-start">
                                <h5 className="main-heading">Address Information</h5>
                                <div className="underline"></div>
                                <p>Phone: +1 (408) 206-XXXX</p>
                                <p>Email: contact@accelerateitnow.com</p>
                                <p>Address: #XXX, San Jose, CA 95148, USA</p>
                                <div className="container">
                                    <GoogleMap />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;