import React from "react";
import Carousel from 'react-bootstrap/Carousel';

import S5 from '../../../images/715263221.png';
import S8 from '../../../images/AdobeStock_399072281.jpeg';

function CloudSecurity() {

    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img src={S5} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                    <h3>Cloud Security & compliance</h3>
                    <p className='slider-p-white'>Ensure robust security and compliance, responsible AI & safeguarding your data while meeting industry standards and regulatory requirements.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>


            <Carousel>
                <Carousel.Item>
                    <img src={S8} className="d-block w-100" alt="Third slide" />
                </Carousel.Item>
            </Carousel>


        </div>
    );
}

export default CloudSecurity;
