import React from "react";
import Carousel from 'react-bootstrap/Carousel';

import S3 from '../../../images/571626413.png';

function AIStrategy() {

    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img src={S3} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                        <h3>Business AI Strategy</h3>
                        <p className='slider-p-white'>Build bespoke data science, machine learning and artificial intelligence solutions.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default AIStrategy;
