import React from 'react';
import Slider from '../inc/Slider';
import Vmc from '../inc/Vmc';
import { Link } from 'react-router-dom';
import OurCompany from '../inc/OurCompany';
import Solutions from '../inc/Solutions';





function Home() {
    return (
        <div>
            <Slider />

            <section className='section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <h3 className='main-heading'>Our Company</h3>
                            <div className='underline mx-auto'></div>
                            <OurCompany />
                            <Link to="/about" className='btn btn-warning shadow'>Read more...</Link>
                        </div>
                    </div>
                </div>
            </section>

            {/* Our vision, mission and values */}
            <Vmc />
            {/* Our serivces*/}
            <Solutions/>

        </div>
    );
}

export default Home;