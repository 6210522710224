import React from "react";
import { Carousel } from "react-bootstrap";
import S4 from '../../images/471311300.png';
import Solutions from "../inc/Solutions";
import ContactUs from "../inc/ContactUs";

function BlogSupport() {
    return (
        <div>

            <section>
                <Carousel>
                    <Carousel.Item>
                        <img src={S4} className="d-block w-100" alt="Third slide" />
                        <Carousel.Caption>
                        <h3>Business operations & support</h3>
                        <p className='slider-p-white'>Offer end-to-end operational support, including software optimization, maintenance, and technical assistance, ensuring your business runs smoothly while you focus on growth.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>

            {/* Solutions */}
            <Solutions/>

            {/*Contact Us */}
            <ContactUs/>

        </div>
    );
}

export default BlogSupport;