import React from "react";
import Carousel from 'react-bootstrap/Carousel';

import S1 from '../../../images/407713520.png';

function GenAI() {

    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img src={S1} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                        <h3>Generative AI</h3>
                        <p className='slider-p-white'>
                            Enhancing customer experiences, driving innovation and optimizing decision making process.
                            <br/>(NLP, Vision, RAG and Reinforcement learning)
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default GenAI;
