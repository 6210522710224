import React from "react";
import Carousel from 'react-bootstrap/Carousel';

import S6 from '../../../images/330513305.png';

function DataStrategy() {

    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img src={S6} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                        <h3>Business AI Strategy</h3>
                        <p className='slider-p-white'>Data strategic insights, and engineering solutions to drive informed decision-making, optimize processes, and fuel business growth.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default DataStrategy;
