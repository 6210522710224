import React from "react";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Ain from '../../images/ain.svg';

import ButtonToolbar from "react-bootstrap/ButtonToolbar";

function AinNav() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme='dark' sticky="top">
            <Container fluid>
                <img src={Ain} className="navbar-brand" alt="Brand logo" height="36" />
                <Navbar.Brand href="/">Accelerate it now</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>

                        <NavDropdown title="Products" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/tunemynetwork" disabled >Smart Connect</NavDropdown.Item>
                            <NavDropdown.Item href="/rightwelfare" disabled >Right Welfare</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Solutions" id="navbarScrollingDropdown">
                            <NavDropdown.Item href="/solutions/businesstransformation">Business Digital Transformation</NavDropdown.Item>
                            <NavDropdown.Item href="/solutions/aistrategy">Business AI Strategy</NavDropdown.Item>
                            <NavDropdown.Item href="/solutions/datastrategy">Business Data Strategy</NavDropdown.Item>
                            <NavDropdown.Item href="/solutions/cloudsecurity">Cloud & Security</NavDropdown.Item>
                            <NavDropdown.Item href="/solutions/genai">Generative AI</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="/supportblogs">Support and Blogs</Nav.Link>

                        <Nav.Link href="/company">Company</Nav.Link>
                    </Nav>

                    <ButtonToolbar >
                        <Button variant="dark" size="sm" className="me-2" href="https://accounts.accelerateitnow.com" target="_blank">Sign in</Button>
                        <Button variant="outline-light" size="sm" href="https://accounts.accelerateitnow.com" target="_blank">Sign up</Button>
                    </ButtonToolbar>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AinNav;
