import React from "react";
import Vmc from '../inc/Vmc';
import OurCompany from "../inc/OurCompany";
import { Carousel } from "react-bootstrap";
import S8 from '../../images/AdobeStock_283166327.jpeg';
import ContactUs from "../inc/ContactUs";

function AboutUs() {
    return (
        <div>

            <Carousel>
                <Carousel.Item>
                    <img src={S8} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                        <h3>Business possiblities</h3>
                        <p className='slider-p-white'>Unlocking new growth opportunites - driving success through innovation and collaboration.</p>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

            <section className="section bg-c-light border-bottom">
                <div className="container">
                    <h5 className="main-heading">Our company</h5>
                    <div className="underline"></div>
                    <OurCompany />
                </div>
            </section>

            <Vmc />

            <ContactUs/>
        </div>
    );
}

export default AboutUs;