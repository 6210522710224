import React from "react";
import { Link } from 'react-router-dom';

import S1 from '../../images/407713520.png';
import S2 from '../../images/137909632.png';
import S3 from '../../images/571626413.png';
import S4 from '../../images/471311300.png';
import S5 from '../../images/715263221.png';
import S6 from '../../images/330513305.png';
import S7 from '../../images/397980226.png';
import S8 from '../../images/380907196.png';

function Solutions () {
    return (
            <section className='section border-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mb-5 text-center'>
                            <h3 className='main-heading'>Blogs</h3>
                            <div className='underline mx-auto'></div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card img-wrapper img-fluid'>
                                <img src={S1} 
                                alt='service1' 
                                className='width:100% border-bottom hover-zoom' />
                                <div className='card-body'>
                                    <h4>Modern business transformation</h4>
                                    <div className='underline'></div>
                                    <p>Adapt and optimize your business to meet evolving technology needs and unlocking new growth opportunites.</p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S2} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Digital transformation</h4>
                                    <div className='underline'></div>
                                    <p>
                                        Lead your business through a successful digital transformation with strategy.
                                    </p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S3} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Artificial Intelligence (AI)</h4>
                                    <div className='underline'></div>
                                    <p>Build bespoke data science, machine learning and artificial intelligence solutions.</p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S4} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Business operations & support</h4>
                                    <div className='underline'></div>
                                    <p>
                                        Offer end-to-end operational support, including software optimization, maintenance, and technical assistance, ensuring your business runs smoothly while you focus on growth.
                                    </p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S5} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Security & compliance</h4>
                                    <div className='underline'></div>
                                    <p>
                                    Ensure robust security and compliance, responsible AI & safeguarding your data while meeting industry standards and regulatory requirements.
                                        </p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S6} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Data strategy</h4>
                                    <div className='underline'></div>
                                    <p>Data strategic insights, and engineering solutions to drive informed decision-making, optimize processes, and fuel business growth.</p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S7} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Data analysis & visualization</h4>
                                    <div className='underline'></div>
                                    <p>
                                    Data analysis, visualization, and dashboards to effectively track and measure business progress.
                                        </p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='card'>
                                <img src={S8} alt='service1' className='width:100% border-bottom' />
                                <div className='card-body'>
                                    <h4>Business possiblities</h4>
                                    <div className='underline'></div>
                                    <p>
                                        Unlock business possibilities—let's grow and thrive together, driving success through innovation and collaboration.
                                    </p>
                                    <Link to='/services' className='btn btn-link' >read more...</Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
    );
}

export default Solutions;
