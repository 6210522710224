import React from "react";
import Carousel from 'react-bootstrap/Carousel';

import S2 from '../../../images/137909632.png';

function DigitalTransformation() {

    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img src={S2} className="d-block w-100" alt="Third slide" />
                    <Carousel.Caption>
                        <h3>Modern business transformation</h3>
                        <p className='slider-p-white'>Adapt and optimize your business to meet evolving technology needs.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default DigitalTransformation;
