import React from "react";

function GoogleMap() {
    return (
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d202875.81560860446!2d-122.0663877182786!3d37.39137844490392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcae48af93ff5%3A0xb99d8c0aca9f717b!2sSan%20Jose%2C%20CA!5e0!3m2!1sen!2sus!4v1724305030279!5m2!1sen!2sus" 
                                width="100%" title="GoogleMap"
                                allowfullscreen="" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade"/>
        </div>
    );
}

export default GoogleMap;