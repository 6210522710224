import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AinNav from './components/inc/AinNav';
import Footer from './components/inc/Footer';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import BlogSupport from './components/pages/BlogSupport';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DigitalTransformation from './components/pages/solutions/DigitalTransformation';
import AIStrategy from './components/pages/solutions/AIStrategy';
import DataStrategy from './components/pages/solutions/DataStrategy';
import CloudSecurity from './components/pages/solutions/CloudSecurity';
import GenAI from './components/pages/solutions/GenAI';

function App() {

  return (
    <Router>
    <div>
    <AinNav/>

      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/solutions/businesstransformation' element={<DigitalTransformation/>} />
        <Route exact path='/solutions/aistrategy' element={<AIStrategy/>} />
        <Route exact path='/solutions/datastrategy' element={<DataStrategy/>} />
        <Route exact path='/solutions/cloudsecurity' element={<CloudSecurity/>} />
        <Route exact path='/solutions/genai' element={<GenAI/>} />
        <Route path='/supportblogs' element={<BlogSupport/>} />
        <Route path='/company' element={<AboutUs/>} />
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
