import React from "react";

function Vmc() {
    return (
        <section className='section bg-c-light border-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mb-5 text-center'>
                            <h3 className='main-heading'>Our vision, mission and values</h3>
                            <div className='underline mx-auto'></div>
                        </div>
                        <div className='col-md-4 text-left'>
                            <h4 className="text-center">Vision</h4>
                            <p>
                            To be the leading force in <i>digital transformation</i>, empowering businesses worldwide with cutting-edge <i>AI and machine learning</i> technologies to thrive in an ever-evolving digital landscape.
                            </p>
                        </div>
                        <div className='col-md-4 text-left'>
                            <h4 className="text-center">Mission</h4>
                            <p>
                            Our mission is to drive innovation by providing tailored software solutions that help businesses optimize their operations, enhance decision-making, and unlock new growth opportunities. We are committed to guiding our clients through every step of their digital transformation journey, ensuring they achieve long-term success and sustainability in a rapidly changing world.
                            </p>
                        </div>
                        <div className='col-md-4 text-left'>
                            <h4 className="text-center">Values</h4>
                            <ul>
                                <li><b>Innovation</b>: We embrace creativity and continuous improvement, always seeking new ways to deliver cutting-edge solutions.</li>
                                <br/><li><b>Integrity</b>: We conduct our business with honesty and transparency, building trust with our clients and partners.</li>
                                <br/><li><b>Customer-Centricity</b>: Our clients' success is our priority; we are dedicated to understanding and meeting their unique needs.</li>
                                <br/><li><b>Excellence</b>: We strive for the highest quality in everything we do, ensuring our solutions exceed expectations.</li>
                                <br/><li><b>Sustainability</b>: We are committed to long-term success, focusing on sustainable practices that benefit our clients, our company, and the environment.</li>
                                <br/><li><b>Collaboration</b>: We believe in the power of teamwork, fostering a collaborative environment that values diverse perspectives.</li>
                            </ul>
                        </div>                        
                    </div>
                </div>
            </section>
    );
}

export default Vmc;